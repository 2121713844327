<template>
    <section v-if="Object.keys($locales).length >= 3">
        <h2 class="mb-3">
            <Icon v="language" />
            <T>links.languageVersions</T>
        </h2>
        <ul class="list-unstyled" style="column-count: 2;">
            <li v-for="(options, locale) in otherLocales" :key="locale" class="mb-1">
                <a :href="options.url">
                    <Icon :v="config.dir === 'rtl' ? 'long-arrow-left' : 'long-arrow-right'" />
                    <!-- <LocaleIcon :locale="options" class="me-1"/> -->
                    {{ options.name }}
                    <small v-if="options.extra" class="text-muted">({{ options.extra }})</small>
                </a>
            </li>
            <li>
                <LocaleLink locale="en" link="/new-version" class="small">
                    <Icon v="plus" />
                    <T>localise.shorter</T>
                </LocaleLink>
            </li>
        </ul>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import type { LocaleDescription } from '../locale/locales.ts';

export default defineComponent({
    setup() {
        return {
            config: useConfig(),
        };
    },
    computed: {
        otherLocales(): Record<string, LocaleDescription> {
            const entries = Object.entries(this.$locales).filter(([locale, _]) => locale !== this.config.locale);
            return Object.fromEntries(entries);
        },
    },
});
</script>
